import { createRouter, createWebHistory ,createWebHashHistory} from 'vue-router'
import Login from '../views/LoginView.vue'
import Index from '../views/indexView.vue'
import jilu from '../views/jiluView.vue'
const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/jilu',
    name: 'jilu',
    component: jilu
  },
  {
    path: '/index',
    name: 'index',
    component: Index
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
