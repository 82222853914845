<template>
  <router-view />
</template>
<script setup>
window.onload = function () {
  document.addEventListener('gesturestart', function (e) {
    e.preventDefault();
  });
  document.addEventListener('dblclick', function (e) {
    e.preventDefault();
  });
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  var lastTouchEnd = 0;
  document.addEventListener('touchend', function (event) {
    var now = (new Date()).getTime();
    if (now - lastTouchEnd <= 300) {
      event.preventDefault();
    }
    lastTouchEnd = now;
  }, false);
}
</script>
<style>
  .-webkit-scrollbar {
    display: none;
    }
   
#app{
 /* 隐藏标准的滚动条 */
 
}
 
</style>
