<template >
  <div class="header"
    style="text-align: center;margin:0 auto;font-size:1rem;font-weight: 700;color: #fff;background-color: #007eff;height:3rem;line-height: 3rem;">
    <span> 会员账号：{{ user.member_username }}</span>
    <span @click='loginOut'> 登出</span>
  </div>

  <van-nav-bar :title="'会员账号：' + user.member_username" class="shoujiView" fixed z-index="10000">
    <template #right>
      <span style='color:#fff' @click='loginOut'>登出</span>
    </template>
  </van-nav-bar>
  <div class="phoneView">
    <van-tabs v-model:active='active' @click-tab="titleClick(active)">
      <van-tab :title="item" v-for="(item, index) in titleArr" :key="index"></van-tab>
    </van-tabs>
  </div>

  <div class="title">
    <span @click='titleClick(index)' v-for="(item, index) in titleArr" :key="index">{{ item }}</span>
  </div>

  <div class="indexContanier">
    <div class="leftcontent">
      <div class="mt-4">
        <span>请选择组名:</span>
        <el-select v-model="value" placeholder="请选择" style="width: 115px" @change="selectChange">
          <el-option :label="item.group_name" :value="item.id" v-for="(item, index) in options" :key="index" />
        </el-select>
      </div>
      <div class="erweimacontent">
        <div class="spanView">
          <span v-for="(item, index) in optionViewValue" :key="index">{{ item.name }}：{{ item.value }}</span>
        </div>
        <div class="erweima" v-show="erweima">
          <qrcode-vue :value="erweima" level="H"></qrcode-vue>
        </div>

      </div>
      <div class="copy">
        <div class="leftButton">
          <el-button @click='copyPhone'>复制号码</el-button>
        </div>
        <div class="rightButton">
          <el-button @click="copyerweima">复制二维码</el-button>
        </div>
      </div>
    </div>
    <div class="rightcontent">
      <div class="mt-4">
        <span>请选择类型:</span>
        <el-select v-model="select" placeholder="请选择" style="width: 115px">
          <el-option :label="item.search_label" :value="item.search_value" v-for="(item, index) in searchList"
            :key="index" />
        </el-select>
      </div>
      <div class="textareaView">
        <el-input v-model="inputValue" :rows="11" resize="none" type="textarea"
          placeholder="请输入查询数据，请按照格式每行一条，不需要逗号分隔，最多查询10条" />
      </div>
      <div class="search">
        <el-button @click="search" :loading="loadingShow">查询</el-button>
        <el-button @click="submit" :loading="SubmitShow">提交</el-button>
      </div>
    </div>
    <div class="tableView">
      <div class="tabletitle">{{ searchText }}</div>
      <el-table :data="tableValue" style="width: 100%" border :cell-class-name="addClass">
        <el-table-column prop="id" label="序号" width="60" />
        <el-table-column prop="number" label="类型" width="70" />
        <el-table-column prop="key" label="内容" width="110" />
        <el-table-column prop="member_username" label="会员账号" width="100" />
        <el-table-column prop="status" label="结果" width="90" />
        <el-table-column fixed="right" prop="create_time" label="时间" width="100" />
      </el-table>
    </div>

  </div>
  <div class="tableView1">
    <div class="tabletitlePc">{{ searchText }}</div>
    <el-table :data="tableValue" style="width: 100%" border :cell-class-name="addClass">
      <el-table-column prop="id" label="序号" width="130" />
      <el-table-column prop="number" label="类型" width="130" />
      <el-table-column prop="key" label="内容" />
      <el-table-column prop="member_username" label="会员账号" width="100" />
      <el-table-column prop="status" label="结果" width="130" />
      <el-table-column fixed="right" prop="create_time" label="时间" width="180" />
    </el-table>
  </div>
</template>
<script setup>
import { ref, reactive } from "vue"
import QrcodeVue from "qrcode.vue"
import { getCurrentInstance } from 'vue'
const { proxy } = getCurrentInstance();
import { showDialog, showNotify, showConfirmDialog } from 'vant'
import axios from "axios";
import { useRouter } from "vue-router"
import useClipboard from 'vue-clipboard3'
const { toClipboard } = useClipboard();
const router = useRouter()
const select = ref("")
const inputValue = ref("")
const loadingShow = ref(false)
const SubmitShow = ref(false)
let inputArr = reactive([])
let tableValue = ref([])
const options = reactive([])
const value = ref()
const optionView = reactive({})
const id = ref('')
const erweima = ref('')
const optionViewValue = reactive([])
const user = reactive([])
const searchList = reactive([])
const searchText = ref('待查结果')
axios.defaults.headers.common['Authorization'] = 'Bearer ' + sessionStorage.getItem('token')
proxy.$post('getSearchTypeList').then(res => {
  if (res.code == 1) {
    Object.assign(searchList, res.data.data)
    
  } else if (res.code == 401) {
    showDialog({
      message: res.data.message
    })
    router.push('/')
    sessionStorage.setItem("token", '')
  } else {
    showDialog({
      message: res.data.message
    })
  }
})
proxy.$post('getUserInfo').then(res => {
  if (res.code == 1) {
    Object.assign(user, res.data)
  } else if (res.code == 401) {
    showDialog({
      message: res.data.message
    })
    router.push('/')
    sessionStorage.setItem("token", '')
  } else {
    showDialog({
      message: res.data.message
    })
  }
})


axios.defaults.headers.common['Authorization'] = 'Bearer ' + sessionStorage.getItem('token')
proxy.$post('getUserGroupList').then(res => {
  if (res.code == 1) {
    Object.assign(options, res.data.data)
  } else if (res.code == 401) {
    showDialog({
      message: res.data.message
    })
    router.push('/')
    sessionStorage.setItem("token", '')
  } else {
    showDialog({
      message: res.data.message
    })
  }
})
function selectChange(item) {
  options.map(p => {
    if (p.id == item) {
      Object.assign(optionView, p)
      erweima.value = optionView.group_qrcode
      id.value = optionView.id
    }
  })
  Object.assign(optionViewValue, [{ name: '编码', value: optionView.id }, { name: '类型', value: optionView.group_name }, { name: '号码', value: optionView.group_number }, { name: '备注', value: optionView.group_remark }])
}
//登出
const loginOut = () => {
  showConfirmDialog({
    message:
      '确认登出吗？',
  })
    .then(() => {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + sessionStorage.getItem('token')
      proxy.$post('Logiout').then(res => {
        if (res.code == 1) {
          showNotify({ type: 'success', message: res.data.message });
          router.push('/')
        } else {
          showDialog({
            message: res.data.message
          })
        }
      })
    })
    .catch(() => {
    });

}
//添加样式
const addClass = (row) => {
  if (row.row.status == '重复') {
    if (row.columnIndex == 4) {
      return 'redcolor'
    }
  } else {
    if (row.columnIndex == 4) {
      return 'greencolor'
    }
  }
}
//复制二维码
const copyerweima = async () => {
  if (value.value == undefined) {
    showDialog({
      message: '请选择组名'
    })
    return
  }
  try {
    await toClipboard(value.value)
    showDialog({
      message: '复制成功'
    })
  } catch {
    showDialog({
      message: '复制失败'
    })
  }
}
//复制号码
const copyPhone = async () => {
  if (value.value == undefined) {
    showDialog({
      message: '请选择组名'
    })
    return
  }
  try {
    await toClipboard(value.value)
    showDialog({
      message: '复制成功'
    })
  } catch {
    showDialog({
      message: '复制失败'
    })
  }
}
//查询弹窗
const showdilog = ref(true)
//提交验证
const submit = async () => {
  searchText.value = '提交结果'
  showConfirmDialog({
    message:
      '5秒提交一次，确定提交吗？',
  }).then(() => {
    if (id.value == '') {
      showDialog({
        message: '请输入提交条件'
      })
      return
    }
    if (inputValue.value == '') {
      showDialog({
        message: '请输入提交条件'
      })
      return
    }
    SubmitShow.value = true
    inputArr = inputValue.value.split(/\n/)
    switch (select.value) {
      case "member_phone":
        const regPhone = /^1[3-9]\d{9}$/;
        let flagPhone = inputArr.every((i) => {
          return regPhone.test(i)
        })
        if (flagPhone == false) {
          setTimeout(() => {
            SubmitShow.value = false
          }, 5000)
          showDialog({
            message: '请输入正确的手机号'
          })
          return
        } else {
          submitShuju()
        }
        break;
      case "member_wechat":
        const regweChat = /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/;
        let flagweChat = inputArr.every((i) => {
          return regweChat.test(i)
        })
        if (flagweChat == false) {
          setTimeout(() => {
            SubmitShow.value = false
          }, 5000)
          showDialog({
            message: '请输入正确的微信号'
          })
          return
        } else {
          submitShuju()
        }
        break;
      case "member_qq":
        const regQQ = /^[1-9][0-9]{4,10}$/
        let flagQQ = inputArr.every((i) => {
          return regQQ.test(i)
        })
        if (flagQQ == false) {
          setTimeout(() => {
            SubmitShow.value = false
          }, 5000)
          showDialog({
            message: '请输入正确的QQ号'
          })
          return
        } else {
          submitShuju()
        }
        break;
      case "member_email":
        const regeMail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        let flageMail = inputArr.every((i) => {
          return regeMail.test(i)
        })
        if (flageMail == false) {
          setTimeout(() => {
            SubmitShow.value = false
          }, 5000)
          showDialog({
            message: '请输入正确的邮箱号'
          })
          return
        } else {
          submitShuju()
        }
        break;
      case "member_douyin":
        const regDouyin = /^[a-zA-Z0-9\u4e00-\u9fa5][a-zA-Z0-9_\-\u4e00-\u9fa5\/\.]{4,}$/
        let flageDouyin = inputArr.every((i) => {
          return regDouyin.test(i)
        })
        if (flageDouyin == false) {
          setTimeout(() => {
            SubmitShow.value = false
          }, 5000)
          showDialog({
            message: '请输入正确的抖音号'
          })
          return
        } else {
          submitShuju()
        }
        break;
      case "member_kwai":
        const KwaiDouyin = /^[a-zA-Z0-9\u4e00-\u9fa5][a-zA-Z0-9_\-\u4e00-\u9fa5\/\.]{4,}$/
        let flagekwai = inputArr.every((i) => {
          return KwaiDouyin.test(i)
        })
        if (flagekwai == false) {
          setTimeout(() => {
            SubmitShow.value = false
          }, 5000)
          showDialog({
            message: '请输入正确快手号'
          })
          return
        } else {
          submitShuju()
        }
        break;
        case  "Tiktok":
        const tikTokreg = /^[a-zA-Z0-9\u4e00-\u9fa5][a-zA-Z0-9_\-\u4e00-\u9fa5\/\.]{4,}$/
        let flagetikTok = inputArr.every((i) => {
          return tikTokreg.test(i)
        })
        if (flagetikTok == false) {
          setTimeout(() => {
            SubmitShow.value = false
          }, 5000)
          showDialog({
            message: '请输入正确的tiktok账号'
          })
          return
        } else {
          submitShuju()
        }
        break;
      case "member_other":
        setTimeout(() => {
          SubmitShow.value = false
        }, 5000)
        submitShuju()
        break;
    }
  })
    .catch(() => {
      // on cancel
    });

}
//提交数据方法
const submitShuju = async () => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + sessionStorage.getItem('token')
  await  proxy.$post('CreateInformation', { group_id: id.value, search_key: select.value, search_value: inputArr }).then(res => {
    if (res.code == 1) {
      showDialog({
        message: '提交成功'
      })
      tableValue.value = res.data.data
      tableValue.value.map(p => {
        p.id = id.value
        p.number = select.value == 'member_phone' ? '手机号' : select.value == 'member_wechat' ? '微信号' : select.value == 'member_qq' ? 'QQ号' : select.value == 'member_email' ? '邮箱' : select.value == 'member_douyin' ? '抖音' : select.value == 'member_kwai' ? '快手' : select.value == 'member_tiktok' ? 'Tiktok' : '其他'
      })
      setTimeout(() => {
        SubmitShow.value = false
      }, 5000)
      inputValue.value = ''
    } else if (res.code == 401) {
      showDialog({
        message: res.data.message
      })
      router.push('/')
      sessionStorage.setItem("token", '')
    } else {
      showDialog({
        message: res.data.message
      })
      setTimeout(() => {
        SubmitShow.value = false
      }, 5000)
    }
  })
}
//查询
const search = async () => {
  searchText.value = '查询结果'
  showConfirmDialog({
    message:
      '5秒查询一次，确定查询吗？',
  })
    .then(() => {
      if (id.value == '') {
        showDialog({
          message: '请输入查询条件'
        })
        return
      }
      if (inputValue.value == '') {
        showDialog({
          message: '请输入查询条件'
        })
        return
      }
      loadingShow.value = true
      inputArr = inputValue.value.split(/\n/)
      switch (select.value) {
        case "member_phone":
          const regPhoneS = /^1[3-9]\d{9}$/;
          let flagPhoneS = inputArr.every((i) => {
            return regPhoneS.test(i)
          })
          if (flagPhoneS == false) {
            setTimeout(() => {
              loadingShow.value = false
            }, 5000)
            showDialog({
              message: '请输入正确的手机号'
            })
            return
          } else {
            serachFunc()
          }
          break;
        case "member_wechat":
          const regweChatS = /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/;
          let flagweChatS = inputArr.every((i) => {
            return regweChatS.test(i)
          })
          if (flagweChatS == false) {
            setTimeout(() => {
              loadingShow.value = false
            }, 5000)
            showDialog({
              message: '请输入正确的微信号'
            })
            return
          } else {
            serachFunc()
          }
          break;
        case "member_qq":
          const regQQS = /^[1-9][0-9]{4,10}$/
          let flagQQS = inputArr.every((i) => {
            return regQQS.test(i)
          })
          if (flagQQS == false) {
            setTimeout(() => {
              loadingShow.value = false
            }, 5000)
            showDialog({
              message: '请输入正确的QQ号'
            })
            return
          } else {
            serachFunc()
          }
          break;
        case "member_email":
          const regeMailS = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
          let flageMailS = inputArr.every((i) => {
            return regeMailS.test(i)
          })
          if (flageMailS == false) {
            setTimeout(() => {
              loadingShow.value = false
            }, 5000)
            showDialog({
              message: '请输入正确的邮箱号'
            })
            return
          } else {
            serachFunc()
          }
          break;
        case "member_douyin":
          const regDouyinS = /^[a-zA-Z0-9\u4e00-\u9fa5][a-zA-Z0-9_\-\u4e00-\u9fa5\/\.]{5,}$/
          let flageDouyinS = inputArr.every((i) => {
            return regDouyinS.test(i)
          })
          if (flageDouyinS == false) {
            setTimeout(() => {
              loadingShow.value = false
            }, 5000)
            showDialog({
              message: '请输入正确的抖音号'
            })
            return
          } else {
            serachFunc()
          }
          break;
          case "member_kwai":
          const regkwaiS = /^[a-zA-Z0-9\u4e00-\u9fa5][a-zA-Z0-9_\-\u4e00-\u9fa5\/\.]{5,}$/
          let flagekwaiS = inputArr.every((i) => {
            return regkwaiS.test(i)
          })
          if (flagekwaiS == false) {
            setTimeout(() => {
              loadingShow.value = false
            }, 5000)
            showDialog({
              message: '请输入正确的快手号'
            })
            return
          } else {
            serachFunc()
          }
          break;
          case "Tiktok":
          const regTiktokS = /^[a-zA-Z0-9\u4e00-\u9fa5][a-zA-Z0-9_\-\u4e00-\u9fa5\/\.]{5,}$/
          let flageTiktokS = inputArr.every((i) => {
            return regTiktokS.test(i)
          })
          if (flageTiktokS == false) {
            setTimeout(() => {
              loadingShow.value = false
            }, 5000)
            showDialog({
              message: '请输入正确的tiktok账号'
            })
            return
          } else {
            serachFunc()
          }
          break;
        case "member_other":
          setTimeout(() => {
            loadingShow.value = false
          }, 5000)
          serachFunc()
          break;
      }
    })
    .catch(() => {
      // on cancel
    });

}
//查询方法
const serachFunc = async () => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + sessionStorage.getItem('token')
  await  proxy.$post('getInformation', { group_id: id.value, search_key: select.value, search_value: inputArr }).then(res => {
    if (res.code == 1) {
      showDialog({
        message: '查询成功'
      })
      tableValue.value = res.data.data
      tableValue.value.map(p => {
        p.id = id.value
        p.number = select.value == 'member_phone' ? '手机号' : select.value == 'member_wechat' ? '微信号' : select.value == 'member_qq' ? 'QQ号' : select.value == 'member_email' ? '邮箱' : select.value == 'member_douyin' ? '抖音' : select.value == 'member_kwai' ? '快手' : select.value == 'member_tiktok' ? 'Tiktok' : '其他'
      })
      setTimeout(() => {
        loadingShow.value = false
      }, 5000)
      inputValue.value = ''
    } else if (res.code == 401) {
      showDialog({
        message: res.data.message
      })
      router.push('/')
      sessionStorage.setItem("token", '')
    } else {
      showDialog({
        message: res.data.message
      })
      setTimeout(() => {
        loadingShow.value = false
      }, 5000)
    }

  })
}
//路由传参M端
const titleArr = reactive(['首页', '查询记录', '入库记录', '重复记录'])
const active = ref(0)
const titleClick = (active) => {
  switch (active) {
    case 1:
      router.push(
        {
          path: '/jilu',
          query: { id: 3, active: 1, user: user.member_username }
        }
      )
      break;
    case 2:
      router.push(
        {
          path: '/jilu',
          query: { id: 0, active: 2, user: user.member_username }
        }
      )
      break;
    case 3:
      router.push(
        {
          path: '/jilu',
          query: { id: 1, active: 3, user: user.member_username }
        }
      )
      break;
  }
}

</script>
<style>
.redcolor {
  color: red;
}

.greencolor {
  color: green;
}

@media screen and (max-width: 450px) {
  .tabletitle {
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    background: #007eff;
    color: #fff;
    height: 1.5rem;
    line-height: 1.5rem;
  }

  .top {
    position: fixed;
    z-index: 99
  }

  .van-nav-bar__title {
    color: #fff !important
  }

  .van-nav-bar--fixed {
    background-color: #007eff !important;
  }

  .tableView1 {
    display: none;
  }

  .header {
    display: none;
  }

  .van-tab:nth-child(3) {
    flex-grow: 2 !important;
  }

  .van-tabs__nav {
    background-color: #f2f3f5 !important;
  }

  .title {
    display: none;
  }


  .indexContanier {
    position: absolute;
    top: 6rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

  }

  .copy {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem
  }

  .search {
    display: flex;
    justify-content: space-around;


  }

  .search>button {
    width: 7rem !important;
  }

  .phoneView {
    position: fixed;
    top: 2.9rem;
    width: 100%;
    z-index: 100000;
  }

  .tableView {
    margin: 0 auto;
    width: 90%;
    padding: 1rem;
  }

  .mt-4 {
    width: 100%;
    /* margin: 1rem; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  .mt-4 span:nth-child(1) {
    margin-right: .2rem;
  }

  .el-select .el-input .el-select__caret.el-icon {
    left: .2rem !important;
  }

  .textareaView {
    width: 90%;
    margin: 1rem auto;
  }

  .erweimacontent {
    margin: 1rem auto;
    margin-top: 1rem;
    display: flex;
    border: 1px solid #dbdbdb;
    padding: 2rem;
    justify-content: space-around;
    width: 75%;
    height: 18rem;
    border-radius: .4rem
  }

  .spanView {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .erweima {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .leftcontent {}

  .erweimacontent span {
    margin: .5rem;
  }
}

@media screen and (min-width: 450px) {
  .title {
    display: flex;
    margin: 1rem auto;
    width: 52rem;
    color: #a5a0a0;
  }

  .tabletitlePc {
    width: 100%;
    height: 2rem;
    background: #007eff;
    line-height: 2rem;
    color: #fff;
    text-align: center;

  }

  .title span:nth-child(1) {
    margin-left: 0 !important;
  }

  .title span {
    margin-left: 1rem;
    cursor: pointer;
  }

  .title span:hover {
    color: #007eff;
  }

  .footer {
    width: 52rem;
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 38px;
    background: #154f06;
    color: #fff;
    height: 3rem;
    line-height: 3rem;
    cursor: pointer;
  }

  .header {
    width: 52rem;
    display: flex;
    justify-content: space-between;
  }

  .header span:nth-child(1) {
    flex: 2
  }

  .header span:nth-child(2) {
    padding-right: 1rem;
    cursor: pointer;
  }

  .jilu {
    text-align: right;
  }

  .indexContanier {
    display: flex;
    width: 52rem;
    padding: 1rem;
    padding-top: 0;
    margin: 0 auto;
    justify-content: space-around;
  }

  .mt-4 span:nth-child(1) {
    margin-right: 1rem;
  }

  .el-select .el-input .el-select__caret.el-icon {
    left: 1rem !important;
  }

  .search {
    position: relative;
    z-index: 99;
    top: 5.5rem;
    display: flex;
    justify-content: space-around;
  }

  .search>button {
    width: 7rem !important;
  }

  .copy {
    display: flex;
    justify-content: space-around;
  }

  .leftcontent {
    width: 47%;
    background-color: #f2f3f5;
    padding: .5rem;
  }

  .mt-4 {
    margin-top: 1rem;
    width: 80%
  }

  .rightcontent {
    width: 47%;
    background-color: #f2f3f5;
    padding: .5rem;
  }

  .textareaView {
    margin-top: 1rem;
    height: 10.5rem
  }

  .rightButton {
    margin-left: 5rem;
    margin-top: 1rem;

  }

  .leftButton {
    margin-top: 1rem
  }

  .erweimacontent {
    flex-wrap: wrap;
    margin-top: 1rem;
    display: flex;
    border: 1px solid #dbdbdb;
    padding: 2rem;
    justify-content: space-around;
    height: 11rem;
    border-radius: .4rem
  }

  .erweimacontent span {
    margin: .5rem;
  }

  .spanView {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .tableView {
    display: none;
  }

  .tableView1 {
    margin-top: 1rem;
    width: 52rem;
    margin: 0 auto;
  }

  .erweima {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .shoujiView {
    display: none;
  }

  .phoneView {
    display: none;
  }
}
</style>