import { createApp } from 'vue'
import App from './App.vue'
// import axios from "axios"
import router from './router'
import vant from 'vant';
import http from './request/request.js'
import "vant/lib/index.css"
import ElementPlus from "element-plus"
import "element-plus/dist/index.css"
// axios.defaults.baseURL = "/api";

createApp(App).use(router).use(vant).use(http).use(ElementPlus).mount('#app')
