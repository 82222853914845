<template lang="">
    
    <div class="header"
      style="text-align: center;margin:0 auto;font-size:1rem;font-weight: 700;color: #fff;background-color: #007eff;height:3rem;line-height: 3rem;">
      <span>  会员账号：{{user}}</span>
      <span @click='loginOut'> 登出</span>
    </div>
    <van-nav-bar :title="'会员账号：'+user" class="shoujiView" fixed z-index="100">
    <template #right>
      <span style='color:#fff' @click='loginOut'>登出</span>
  </template>
  </van-nav-bar>
    <div class="phoneView1">
      <van-tabs ref='domref' v-model:active='active'  @click="titleClick(active)" >
        <van-tab :title="item" v-for="(item,index) in titleArr" :key="index"></van-tab>
      </van-tabs>
    </div>

  <div class="title">
    <span @click='titleClick(index)'  v-for="(item,index) in titleArr" :key="index">{{item}}</span>
  </div>
  <div class="contentjilu">
    <el-table :data="recordArr" style="width: 100%" border :cell-class-name="addClass" class='phoneTable'>
    <el-table-column prop="id" label="序号" width='60' />
    <el-table-column prop="search_key" width='70' label="类型"/>
    <el-table-column prop="search_value" label="内容" :show-overflow-tooltip="true"/>
    <el-table-column prop="member_username" label="会员账号" width="100" />
    <el-table-column prop="search_status" label="结果" width='60'/>
    <el-table-column prop="create_time" width='100' fixed="right" label="时间  " />
  </el-table>
  <el-table :data="recordArr" style="width: 100%" border :cell-class-name="addClass"  class='pcTable'>
    <el-table-column prop="id" label="序号" width='90' />
    <el-table-column prop="search_key" width='130' label="类型"/>
    <el-table-column prop="search_value" label="内容" width='210'/>
    <el-table-column prop="member_username" label="会员账号" width="100" />
    <el-table-column prop="search_status" label="结果" width='90'/>
    <el-table-column prop="create_time" width='210' fixed="right" label="时间  " />
  </el-table>
  <ElConfigProvider :locale='zhCn'>
  <el-pagination
    background
    @current-change = "hadleCurrentChange"
    :page-sizes="[10,15,20]"
    layout="total,jumper,prev, pager, next "
    :pager-count = "5"
    :total="total"
    small
  >
</el-pagination>
</ElConfigProvider >
  </div>
 
</template>
<script setup>
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import { ElConfigProvider } from "element-plus"
import { getCurrentInstance } from 'vue'
const { proxy } = getCurrentInstance();
import { ref, reactive, onMounted, watch, onBeforeUpdate } from "vue"
import { useRouter, useRoute } from "vue-router"
import { showDialog } from 'vant'
const router = useRouter()
const route = useRoute()
import axios from "axios";
let recordArr = ref([])
const pageIndex = ref(1)
const pageSize = ref(10)
const total = ref(0)
const searchStatus = ref(0)
const request = async() => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + sessionStorage.getItem('token')
 await proxy.$post('getSearchInforMationList', { search_status: searchStatus.value, pageIndex: pageIndex.value, pageSize: pageSize.value }).then(res => {
    if (res.code == 1) {
      recordArr.value= res.data.data
      total.value = res.data.total
      recordArr.value.map(p => {
        p.search_status = p.search_status == 1 ? '重复' : '正常'
      })
    } else if (res.code == 401) {
      showDialog({
        message: res.data.message,
      })
      router.push('/')
      sessionStorage.setItem("token", '')
    } else {
      showDialog({
        message: res.data.message,
      })
    }
  })
}

const hadleCurrentChange = (size) => {
  pageIndex.value = size
  request()
}
const addClass = (row) => {
  if (row.row.search_status == '重复') {
    if (row.columnIndex == 4) {
      return 'redcolor'
    }
  } else {
    if (row.columnIndex == 4) {
      return 'greencolor'
    }
  }
}
//登出
const loginOut = () => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + sessionStorage.getItem('token')
  proxy.$post('Logiout').then(res => {
    if (res.code == 1) {
      showDialog({
        message: res.data.message
      })
      router.push('/')
    } else {
      showDialog({
        message: res.data.message
      })
    }
  })
}
//路由传参M端
const titleArr = reactive(['首页', '查询记录', '入库记录', '重复记录'])
const active = ref(0)
if (route.query.active == 2) {
  active.value = 2
} else if (route.query.active == 1) {
  active.value = 1
} else {
  searchStatus.value = 1
}
const titleClick = (active) => {
  recordArr.value = []
  pageIndex.value = 1
  if (active == 0) {
    router.push('/index')
  } else if (active == 2) {
    searchStatus.value = 0
    request()
  } else if (active == 3) {
    searchStatus.value = 1
    request()
  } else {
    searchStatus.value = 3
    request()
  }
}
const user = ref('')
onMounted(() => {
  searchStatus.value = route.query.id
  user.value = route.query.user
  request()

})

</script>
<style>
  /* .cell{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  } */

@media screen and (max-width: 450px) {
  .pcTable {
    display: none;
  }

  .title1 {
    display: none;
  }

  .phoneView1 {
    position: fixed;
    top: 2.9rem;
    width: 100%;
    z-index: 99;
  }

  .contentjilu {
    margin-top: 6rem !important;
    width: 100%;
    margin: 0 auto;
  }

  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    margin: 0 !important;
  }
}

.el-pagination {
  margin-top: .5rem;
  margin-left: .3rem
}

@media screen and (min-width: 450px) {

  .phoneTable {
    display: none;
  }

  .contentjilu {
    width: 52rem;
    margin: 0 auto;
  }

  .shoujiView {
    display: none;
  }

  .phoneView1 {
    display: none;
  }

  .el-pagination {
    margin-top: 20rem;
    margin-left: 21rem;
  }
}</style>